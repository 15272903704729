import React, { useState } from 'react'
import { GetLandingPageStyle } from './GetStartedProductPage.style';
import DialogComponent from '../DialogComponent';

const GetLandingPage = (props) => {
    const { landingPageData } = props;
    const [openPopup, setOpenPopup] = useState(false);


    function handleClick() {
        if (landingPageData?.video_url) {
            setOpenPopup(true)
        }
        else {
            setOpenPopup(false)
        }
    };

    return (
        <GetLandingPageStyle>
            {landingPageData &&
                <div className='main-section-landing' style={{ backgroundImage: `url('../images/AssetBlog.png')` }}>
                    <div className='container'>
                        <div className='row' style={{ display: "flex" }}>
                            <div class="media-wrap">
                                {!landingPageData?.video_url ?
                                    <div className='image-section'>
                                        <img src={`${process.env.API_URL}${landingPageData?.image}`} />
                                    </div>
                                    :
                                    <div className='video-section'>
                                        <img src={`${process.env.API_URL}${landingPageData?.video_thumbnail}`} onClick={handleClick} />
                                    </div>
                                }

                            </div>
                            <div class="media-content-wrap">
                                <div className='heading-section'>
                                    <h2 className='heading-sec'>{landingPageData?.heading}</h2>
                                </div>
                                <div className='heading-section'>
                                    <h3 style={{ color: "#000" }}>{landingPageData?.content}</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
            <DialogComponent classFor="display_youtube" opend={openPopup} handleClose={() => {
                setOpenPopup(false)
            }} title={''}>
                <div className="youtube_video" style={{ textAlign: "center" }}>
                    <iframe style={{
                        width: "100%",
                    }} height="400" src={landingPageData?.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </DialogComponent>
        </GetLandingPageStyle>
    )
}

export default GetLandingPage