import React from 'react'
import _ from 'lodash';
import { LinkAnchor } from '@Components/Common/CommonMethods';
import { PERSONALIZED_ICONS } from '@Components/Common/Constents';
import router from 'next/router';
import VedioPlayer from '@Components/Common/VedioPlayer';
function HomeSectionFour(props) {

    return (
        <div className='home_fourth_section seen-on'>
            <div className='fourth_first_container'>
                <h2>CODE Complex</h2>
                <h3>Nearly 400 million combinations possible.</h3>
                <h3><p><span>ONE</span> FORMULATION FOR  <span className='color-gradient'>YOU.</span></p> </h3>
            </div>
            <div className='fourth_second_container'>
                <div className='capsule'>
                    <div>
                        <img src="/images/code_comple.png" />
                    </div>
                    <div>
                        <p>CODE Complex is a revolutionary DNA-customized<br />nutritional supplement.</p>
                        <p className='boldOne'><b>Personalized biohacking simplified.</b></p>
                        <p>
                            Containing key vitamins, minerals and micronutrients,<br />
                            your CODE Complex formula gives your body<br />
                            what your body needs.
                        </p>
                        <div style={{ marginTop: "30px" }}>
                            <LinkAnchor href="/get_started/">Get Your DNA Test Kit
                            </LinkAnchor>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fourth_third_container '>
                <div className='personalized_icons'>
                    {_.map(PERSONALIZED_ICONS, ({ icon, text }, index) => (
                        <div key={index} className='iconContainer'>
                            <span><img src={`/images/${icon}`} /></span>
                            <p>{text}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='as_seen_section'>
                <div className="cstm_container">
                    <div className='new_titles'>
                        <h2 style={{ color: "#000" }}>AS SEEN ON</h2>
                    </div>
                    <div className='logo-cstm-wrap'>
                        <ul>
                            <li>
                                <figure>
                                    <img src={`/images/nbcIcon.png`} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={`/images/fox.png`} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={`/images/abcIcon.png`} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={`/images/ocbs.png`} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={`/images/digitalJournal.png`} />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={`/images/boston.png`} />
                                </figure>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='first_container' style={{ width: "100%" }}>
                <h3>Are you a Wellness professional?</h3>
                <p><button onClick={() => router.push("/us/wellness_professionals")}>learn more</button></p>
            </div>
            <div className='right-img'><img src="/images/molecular_right.png" /></div>

        </div>
    )
}

HomeSectionFour.propTypes = {}

export default HomeSectionFour